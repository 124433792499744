import React, { useState } from "react";
import { CustomOtp } from "../../components/input/input";
import { ResendOTP } from "otp-input-react";
import { forgotPassword } from "../../redux1/Slices/registerSlice";
import { useDispatch } from "react-redux";
import PrimaryButton from "../buttons/button";

const CustomOtpCard = ({
  otp,
  setOtp,
  state,
  email,
  onClick,
  ShowEmail,
  setShowOtp,
}) => {
  const dispatch = useDispatch();
  const handleResend = () => {
    const data = {
      email,
    };
    dispatch(forgotPassword({ data, setShowOtp }));
  };
  const renderButton = (buttonProps) => {
    return (
      <button
        className="border-none text-sm bg-transparent skyblue cursor-pointer text-sm font-medium my-2 shadow-none"
        {...buttonProps}
      >
        {buttonProps.remainingTime !== 0 ? (
          <span
            className="font-semibold"
            style={{ color: "rgba(193, 193, 193, 1)" }}
          >
            Resend Code <span className="text-900 font-base"> in </span>
            <span className="pink font-semibold">
              {buttonProps.remainingTime} sec
            </span>
          </span>
        ) : (
          <span className="blue font-semibold text-sm " onClick={handleResend}>
            Resend Code
          </span>
        )}
      </button>
    );
  };
  const renderTime = (remainingTime) => {
    return (
      <div className="col-12 flex justify-content-center text-center mt-2 mx-0 px-0 ">
        {/* <p className="mt-0 flex align-items-end text-blue-300 ">
              {remainingTime} sec
            </p> */}
        <PrimaryButton
          className="w-full"
          extraClass={""}
          col={4}
          label="Continue"
          onClick={onClick}
        />
      </div>
    );
  };
  return (
    <>
      <div className="signup  md:col-7 lg:col-4 col-11 lg:p-4 p-4 my-3 ">
        <div className="text-center ">
          <div className="kagitingan lg:text-5xl text-xl mb-3">
            OTP VERIFICATION
          </div>
          <span className="text-sm subheading">
            Enter the 6-digit verification code sent to
          </span>
          <div className="text-sm subheading mb-3">
            {state?.email ? state?.email : ShowEmail}
          </div>
        </div>
        <CustomOtp
          onChange={(e) => setOtp(e)}
          value={otp}
          col={12}
          extraClassName={"mb-2"}
        />
        <p
          className="col-12 m-0 p-0 text-lg  font-medium"
          style={{ color: "#425466" }}
        >
          <ResendOTP
            // onResendClick={() => sendEmailApi(email, navigate, dispatch)}
            renderTime={renderTime}
            renderButton={renderButton}
            className="flex-column-reverse "
          />
        </p>
      </div>
    </>
  );
};

export default CustomOtpCard;
